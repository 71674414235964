'use client';

import { useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { getProductWebBrowseHistory } from '@/lib/services/zhuye';
import { Goods } from '@/components/commons';
import { useAuthProvider, useDarkModeProvider } from '@/lib/context';

/**
 * 最近浏览商品 H5
 * @returns
 */
const RecentlyViewedForH5 = ({
  size,
  noMoreButton = false,
  selectedGoods,
  handleCollection,
}: {
  size?: number;
  noMoreButton?: boolean;
  selectedGoods?: any;
  handleCollection?: (value: boolean, item: API.WebProductItemVo) => void;
}) => {
  const { isLogin } = useAuthProvider();

  const [isLoginNew, setIsLoginNew] = useState<boolean>(false);
  const [renderData, setRenderData] = useState<any[]>([]);

  useEffect(() => {
    setIsLoginNew(!!isLogin);
  }, [isLogin]);

  const t = useTranslations('Home');
  const { darkMode } = useDarkModeProvider();

  // 最近浏览商品列表 -- 需要登录
  const { run } = useRequest(
    () =>
      getProductWebBrowseHistory({
        pageNo: 1,
        pageSize: size ? 6 : 30,
      }),
    {
      manual: true,
      cacheKey: 'recent-browse-history-h5',
      onSuccess: (response) => {
        setRenderData(response?.data?.list || []);
      },
    },
  );
  useEffect(() => {
    if (isLoginNew) {
      run();
    }
  }, [isLoginNew, run]);

  useEffect(() => {
    if (selectedGoods) {
      setRenderData((renderData || []).map((x) => (x.productId === selectedGoods.productId ? selectedGoods : x)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoods]);

  return renderData && renderData?.length >= 3 ? (
    <section
      className={`box-border w-full ${darkMode ? 'bg-black' : 'bg-[#f5f6f6]'} ${noMoreButton ? '' : 'mb-3 py-3'}`}
    >
      {noMoreButton ? (
        ''
      ) : (
        <h2 className={`mb-3 text-base font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>
          {t('recentlyViewed')}
        </h2>
      )}
      <div className={`flex w-full flex-row items-start justify-between overflow-x-auto`}>
        {(renderData || []).map((x) => {
          return (
            <div key={x.productId} className="mr-2 w-36 last:mr-0">
              <Goods
                item={{
                  ...x,
                  favorited: x.productId === selectedGoods?.productId ? selectedGoods?.favorited : x.favorited,
                }}
                width={144}
                noAnimation
                handleCollection={(value, goods) => {
                  if (handleCollection) {
                    handleCollection(value, goods);
                  }

                  const newData = renderData ? [...renderData] : [];
                  if (value) {
                    const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                    if (index > -1) {
                      newData[index].favorited = true;
                    }
                  } else {
                    const index = (newData || []).findIndex((goodItem) => goodItem.productId === goods.productId);
                    if (index > -1) {
                      newData[index].favorited = false;
                    }
                  }
                  setRenderData(newData);
                }}
              />
            </div>
          );
        })}
      </div>
    </section>
  ) : (
    ''
  );
};

export default RecentlyViewedForH5;
